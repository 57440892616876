import React from 'react';
import DrawerAppBar from './Toolbar.js';
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import X from "@mui/icons-material/X"

function Blog() {
    return (
      <>
        <DrawerAppBar />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', backgroundColor: 'white' }}>
          <p style={{ fontSize: '24px', marginTop: '-20vh' }}>I'm working on it!</p>
        </div>
        <div style={{position: 'fixed', bottom: '0', width: '100%', backgroundColor: '#1C1C1C', color: 'white', display: 'flex', justifyContent: 'space-between', padding: '10px 20%'}}> 
          <IconButton color="inherit" href="https://twitter.com/WeinbaumJonah" target="_blank"><X /></IconButton>
          <IconButton color="inherit" href="https://www.linkedin.com/in/weinbaumjonah/" target="_blank"><LinkedInIcon /></IconButton>
          <IconButton color="inherit" href="mailto:weinbaumjonah@gmail.com"><EmailIcon /></IconButton>
        </div>
      </>
    );
  }

export default Blog;
