import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Startups from './Startups';
import Music from './Music';
import Blog from './Blog';
import App from './App';

function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/quotes" element={() => window.location.href = 'https://mixed-hound-c26.notion.site/Quotes-a56062b2bf7342ecb9c6edd9ec1c1d60?pvs=74'} />
        <Route path="/music" element={<Music />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/home" element={<App />} />
        
        {/* other routes */}
      </Routes>
    </Router>
  );
}

export default Main;