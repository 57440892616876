import React, { useEffect, useRef } from 'react';
import DrawerAppBar from './Toolbar.js';
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import X from "@mui/icons-material/X"
import audio from "./WHY ARE YOU CLICKING ON THIS.m4a"

function Music() {
    const audioRef = useRef(null);

    useEffect(() => {
        if (audioRef.current) {
            const audioElement = audioRef.current;
            audioElement.addEventListener('loadedmetadata', () => {
                audioElement.currentTime = Math.random() * audioElement.duration;
            });
            audioElement.addEventListener('ended', () => {
                audioElement.currentTime = 0;
            }, false);
        }
    }, []);

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    }

    return (
      <>
        <DrawerAppBar />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', backgroundColor: 'white', cursor: 'pointer' }} onClick={playAudio} title="Click Me">
          <div style={{width:'100%',height:'0',paddingBottom:'73%',position:'relative'}}>
            <iframe src="https://giphy.com/embed/cCMs2Z2JXREgo" width="100%" height="100%" style={{position:'absolute', pointerEvents: 'none'}} frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
        <audio ref={audioRef} src= {audio} style={{ height: '0', width: '0' }} />
        <div style={{position: 'fixed', bottom: '0', width: '100%', backgroundColor: '#1C1C1C', color: 'white', display: 'flex', justifyContent: 'space-between', padding: '10px 20%'}}> 
          <IconButton color="inherit" href="https://twitter.com/WeinbaumJonah" target="_blank"><X /></IconButton>
          <IconButton color="inherit" href="https://www.linkedin.com/in/weinbaumjonah/" target="_blank"><LinkedInIcon /></IconButton>
          <IconButton color="inherit" href="mailto:weinbaumjonah@gmail.com"><EmailIcon /></IconButton>
        </div>
      </>
    );
  }

export default Music;